@import "../../styles/vars";
@import "../../styles/media";

.header {
  padding: 28px 0;

  @media (max-width: $tablet) {
    padding: 15px 0;
  }

  &__holder {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    width: 100%;
    height: 44px;
    overflow: visible;
    text-indent: -9999px;
    background: url(../../assets/img/logo_default.svg) 0 50% / contain no-repeat;

    @media (max-width: $tablet) {
      width: 100%;
      height: 30px;
    }
  }

  &__logo-link {
    display: block;
    height: 100%;
  }

  & .btn.btn--secondary {
    padding: 0px 19px;

    @media (max-width: $tablet) {
      padding: 0px 15px;
    }
  }
}
