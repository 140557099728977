@import "../../styles/vars";
@import "../../styles/media";

.possibility {
  margin-bottom: 60px;

  @media (max-width: $tablet) {
    margin-bottom: 30px;
  }

  &__holder {
    display: flex;
    border: 1px solid #CECECE;
    padding: 60px 30px;

    @media (max-width: $tablet) {
      flex-direction: column-reverse;
      padding: 30px;
    }
    @media (max-width: $mobile) {
      padding: 0;
      border: 0;
    }
  }

  &__charts {
    width: 50%;
    z-index: 1;

    @media (max-width: $tablet) {
      width: 100%;
    }
    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__charts-container {
    padding-bottom: 20px;
    margin-bottom: 30px;
    min-height: 50px;
    position: relative;
  }

  &__charts-item {
    margin-bottom: 11px;
  }

  &__charts-linescale {
    position: absolute;
    left: 0;
    top: 12px;
    width: 100%;
    height: calc(100% - 12px);
    z-index: -1;
    display: flex;
    color: $dark-gray;
    font-size: 12px;
    line-height: 17px;
  }

  &__charts-linescale-item {
    flex: 1;
    height: 100%;
    position: relative;
    border-right: 1px dashed #ccc;

    &.is-first {
      flex: 0;
    }
  }

  &__charts-linescale-value {
    position: absolute;
    top: 100%;
    margin-top: 7px;
    right: 0;
    transform: translateX(50%);
  }

  &__charts-underline {
    display: block;
    font-size: 12px;
    line-height: 18px;
  }

  &__description {
    width: 50%;
    display: flex;
    padding: 0 35px;

    @media (max-width: $tablet) {
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }
    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
  }

  &__description-img {
    margin-right: 30px;
    max-width: 60px;

    @media (max-width: $tablet) {
      margin-right: 10px;
    }
  }

  &__description-holder {
    flex: 1;
  }

  &__description-title {
    font-size: 18px;
    line-height: 24px;
    color: $primary;
    margin: 0 0 10px;
    font-weight: normal;
  }

  &__description-text {
    margin: 0;
  }
}