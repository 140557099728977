@import "../../styles/vars";
@import "../../styles/media";

@keyframes spinnerAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($white, 0.7);
  z-index: 20;
  opacity: 1;

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    border: 8px solid $lighter-blue;
    border-bottom-color: $primary;
    border-radius: 50%;
    animation: spinnerAnimation 0.4s linear infinite;
  }
}
