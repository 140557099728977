@import "../../styles/vars";
@import "../../styles/media";

.description {
  color: $primary;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: $tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__text {
    font-size: 24px;
    line-height: 36px;
    width: 730px;
    max-width: 100%;
    margin:  0 auto 60px;
    text-align: center;

    @media (max-width: $tablet) {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__list-item {
    width: 33.33%;
    padding: 40px 15px 15px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: $tablet) {
      padding-top: 30px;
    }
    @media (max-width: $mobile) {
      padding: 30px 0 15px;
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: $primary url(../../assets/img/flash-icon.svg) no-repeat 50% 50% / 37px 37px;

      @media (max-width: $tablet) {
        width: 60px;
        height: 60px;
      }
    }
  }

  &__list-item-holder {
    background: $lighter-blue;
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: center;
    padding: 62px 30px 30px;

    @media (max-width: $tablet) {
      padding: 44px 15px 20px;
    }
  }
}