.btn-holder {
  display: flex;
  margin-left: -40px;

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &--justify {
    justify-content: space-between;
  }

  .btn {
    margin-left: 40px;
  }
}

.btn {
  font-family: $frutiger;
  background: $secondary;
  color: $white;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  border: 0;
  text-align: center;
  transition: all 0.15s linear;

  &:hover {
    background: $orange;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      background: $secondary;
    }
  }

  &--secondary {
    background: none;
    border: 1px solid $primary;
    color: $primary;
    padding: 14px 19px;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  &--min-width {
    min-width: 126px;
  }
}