@import "../../styles/vars";
@import "../../styles/media";

.steps-info {
  margin-bottom: 160px;

  @media (max-width: $tablet) {
    margin-bottom: 40px;
  }

  &__holder {
    display: flex;

    @media (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__title {
    margin-bottom: 0;
    text-align: left;
    min-width: 335px;
    width: 335px;
    flex-shrink: 1;
    margin-right: 50px;
    word-wrap: break-word;

    @media (max-width: $tablet) {
      margin-right: 20px;
      min-width: 300px;
      width: 300px;
    }
    @media (max-width: $mobile) {
      width: auto;
      min-width: 0;
      margin: 0 0 30px;
    }
  }

  &__col {
    flex: 1;
  }

  &__row {
    position: relative;
    padding-left: 84px;
    min-height: 60px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      padding-left: 43px;
      margin-top: 30px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__row-number {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 36px;
    color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $light-blue;
    font-weight: bold;

    @media (max-width: $tablet) {
      width: 35px;
      height: 35px;
      font-size: 18px;
      line-height: 31px;
      padding-top: 3px;
    }
  }

  &__row-title {
    color: $primary;
    margin-bottom: 11px;

    @media (max-width: $tablet) {
      font-size: 20px;
      line-height: 26px;
    }

    span {
      display: block;
    }
  }

  &__row-text {
    margin: 0;
    color: $dark-gray;

    @media (max-width: $tablet) {
      margin-left: -43px;
    }
  }
}
