@import "../vars";
@import "../media";

.result {
  &__privat {
    padding-top: 60px;
    margin-bottom: 120px;

    @media (max-width: $tablet) {
      padding-top: 0;
      margin-bottom: 40px;
    }

    .btn {
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }
}