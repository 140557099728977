.questionnaire {
  &__steps {
    color: $light-blue;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 15px;

    @media (max-width: $mobile) {
      line-height: 30px;
    }
  }

  &__content {
    width: 100%;
    padding: 65px 20px;
    display: flex;
    flex-direction: column;
    align-content: center;

    .radio-list {
      width: 600px;
      max-width: 100%;
      margin: 0 auto 70px;

      @media (max-width: $mobile) {
        margin-bottom: 28px;
      }
    }
  }

  &__action {
    display: block;

    &:after {
      display: table;
      clear: both;
      content: '';
    }
  }

  #back {
    float: left;
  }

  #next {
    float: right;
  }
}