@import "../../styles/vars";

.switcher {
  display: flex;
  align-items: center;

  &__body {
    background: #F4F5F6;
    border-radius: 17px;
    height: 20px;
    width: 33px;
    position: relative;
    display: block;
    padding: 0;
    border: 0;
    margin-right: 14px;
    transition: background 0.15s linear;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      background: $white;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.235714);
      border-radius: 50%;
      transition: transform 0.15s linear;
    }
  }

  &.active {
    .switcher {
      &__body {
        background: #33649D;

        &:before {
          transform: translateX(13px);
        }
      }
    }
  }
}