@import "../../styles/vars";
@import "../../styles/media";

.intro {
  background: $primary;
  color: $white;
  margin-bottom: 100px;

  @media (max-width: $tablet) {
    margin-bottom: 0;
  }

  &__holder {
    display: flex;
    align-content: center;
    padding-left: 165px;
    justify-content: space-between;

    @media (max-width: 1439px) {
      padding-left: 15px;
    }
    @media (max-width: $mobile) {
      flex-direction: column-reverse;
    }
  }

  &__col {
    width: 50%;
    max-width: 100%;
    padding: 100px 0;

    @media (max-width: $tablet) {
      padding: 30px 0;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }

    &--resize {
      flex: 1;
    }
  }

  &.has-video{
    margin-bottom: 40px;

    .intro__holder {
      @media (max-width: $mobile) {
        flex-direction: column;
      }
    }
  }

  &__overline {
    font-size: 24px;
    line-height: 36px;
    font-weight: normal;
    margin: 0 0 30px;
  }

  &__sub-text {
    margin-bottom: 10px;
    display: block;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: $tablet) {
      line-height: 30px;
    }

    &:last-child {
      @media (max-width: $mobile) {
        margin-bottom: 0;
      }
    }

    &--bold {
      font-weight: bold;
      line-height: 30px;
    }
  }

  &__btn-wrap {
    padding-top: 20px;

    .btn {
      @media (max-width: $mobile) {
        flex: 1;
      }
    }
  }

  &__img {
    width: 50%;
    display: flex;
    position: relative;
    margin-right: -15px;

    @media (max-width: $mobile) {
      margin: 0 -15px;
      width: calc(100% + 30px);
      height: 240px;
    }

    &:before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      width: 75px;
      background: $primary;
      clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
      z-index: 2;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    img {
      object-fit: cover;
    }
  }

  &__video {
    width: 350px;
    height: 260px;
    display: flex;
    align-self: center;
    position: relative;
    margin-left: 30px;

    @media (max-width: $tablet) {
      height: 220px;
    }
    @media (max-width: $mobile) {
      width: 100%;
      margin: 0 0 40px;
    }

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background:  linear-gradient(180deg, rgba(255, 102, 0, 0) 0%, #FF6600 100%);
    }
  }

  &__video-play {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    padding: 0;
    background: url(../../assets/img/play-icon.svg) no-repeat 50% 50% / cover;
    z-index: 2;

    @media (max-width: $tablet) {
      width: 76px;
      height: 76px;
    }
  }

  &__video-text {
    color: $white;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    z-index: 2;

    @media (max-width: $tablet) {
      bottom: 9px;
    }
  }

  &__window {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    align-items: center;
    justify-content: center;
    display: none;

    &.show {
      display: flex;
    }
  }

  &__window-body {
    width: 1000px;
    max-width: calc(100% - 30px);
    padding: 10px;
    background: $white;
    position: relative;
    z-index: 3;

    video {
      max-width: 100%;
      display: block;
    }
  }

  &__window-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.8);
    z-index: 2;
  }

  &__window-close {
    position: absolute;
    top: -50px;
    right: 0;
    width: 40px;
    height: 40px;
    background: none;
    padding: 0;
    border: 0;
    transform: rotate(45deg);

    &:hover {
      &:before,
      &:after {
        background: $orange;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background: $white;
      transform: translate(-50%, -50%);
      border-radius: 2px;
    }

    &:before {
      width: 4px;
      height: 40px;
    }

    &:after {
      width: 40px;
      height: 4px;
    }
  }

  &__footer {
    color: $white;
    background: $light-blue;
    overflow: hidden;
    z-index: 1;
  }

  &__footer-holder {
    display: flex;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 165px;
    position: relative;
    z-index: 1;

    @media (max-width: 1439px) {
      padding-left: 15px;
    }
    @media (max-width: $tablet) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @media (max-width: $mobile) {
      flex-direction: column;
      padding-bottom: 40px;
      padding-top: 26px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 162px;
      height: 336px;
      background: $blue;
      top: 50%;
      transform: translateY(-50%) rotate(17deg);
      z-index: -1;

      @media (max-width: $tablet) {
        display: none;
      }
    }

    &:before {
      left: -50px;
    }

    &:after {
      right: -50px;
    }

  }

  &__footer-text {
    flex: 1;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;

    @media (max-width: $tablet) {
      font-size: 18px;
      line-height: 30px;
      font-weight: normal;
    }
    @media (max-width: $mobile) {
      margin-bottom: 15px;
    }

    p {
      margin: 0;
    }
  }

  &__footer-btn {
    margin-left: 15px;

    @media (max-width: $mobile) {
      width: 100%;
      margin-left: 0;
    }
  }
}