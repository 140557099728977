.page {
  &__main {
    &--small {

      background: $lighter-blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 15px;

      .page__holder {
        width: 1110px;
        max-width: 100%;
        display: flex;
        align-content: center;
        background: $white;

        @media (max-width: $mobile) {
          flex-direction: column;
        }
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    text-align: center;
    width: 920px;
    max-width: 100%;
    margin: 0 auto;

    @media (max-width: $mobile) {
      min-height: 0;
    }
  }

  &__title {
    color: $primary;
    font-size: 32px;
    line-height: 46px;

    @media (max-width: $mobile) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  &__avatar {
    position: absolute;
    left: 50%;
    top: 0;
    display: flex;
    transform: translate(-50%, -50%);

    &,
    img {
      border-radius: 50%;
      overflow: hidden;
      width: 120px;
      height: 120px;

      @media (max-width: $mobile) {
        width: 100px;
        height: 100px;
      }
    }

    img {
      object-fit: cover;
    }
  }
}