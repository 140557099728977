html {
  scroll-behavior: smooth;
}

body {
  font: 400 14px/20px $frutiger;
  color: $dark-gray;
  max-width: 100%;
}

.container {
  width: 1140px;
  max-width: 100%;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;

  &--big {
    width: 1440px;

    @media (max-width: 1439px) {
      width: 1140px;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-content: center;

  &__main {
    flex: 1;
  }
}

.title {
  font-weight: bold;
  margin: 0 0 30px;
  font-size: 40px;
  line-height: 50px;

  @media (max-width: $tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  &--02 {
    color: $light-blue;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 30px;

    @media (max-width: $tablet) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  &--03 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}

.hidden {
  display: none !important;
}

.description-row {
  display: block;
  margin-bottom: 40px;
  text-align: center;
  color: $dark-gray;
  font-size: 18px;
  line-height: 24px;

  @media (max-width: $tablet) {
    margin-bottom: 50px;
  }
}
