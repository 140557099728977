@import "../../styles/vars";
@import "../../styles/media";

.missing-finance {
  margin-bottom: 15px;

  &__holder {
    display: flex;
    padding: 50px 30px;
    border: 1px solid #CECECE;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
    @media (max-width: $mobile) {
      padding: 0;
      border: 0;
    }
  }

  &__chart {
    min-width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: $tablet) {
      margin-bottom: 60px;
    }
    @media (max-width: $mobile) {
      display: none;
    }

    .switcher {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__chart-holder {
    margin-bottom: 16px;
  }

  &__description {

    width: 49.9%;
    display: flex;
    padding: 0 35px;

    @media (max-width: $tablet) {
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }
    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
  }

  &__description-icon {
    margin-right: 37px;
    max-width: 60px;

    @media (max-width: $tablet) {
      margin-right: 10px;
    }
  }

  &__description-holder {
    flex: 1;
  }

  &__description-title {
    font-size: 18px;
    line-height: 24px;
    color: $primary;
    margin: 0 0 10px;
    font-weight: normal;
  }

  &__description-text {
    margin: 0;
  }

  &__pie {
    width: 490px;
    height: 220px;
    position: relative;
    overflow: hidden;

    
    & .missing-finance__pie-img {
      opacity: 1;
      z-index: 1;
    }
  }

  &__pie.graph4 {
    height: auto;
  }

  &__pie-img {
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 0.15s linear;
  }
}