@import "../../styles/media";

.advices {
  margin-bottom: 120px;

  &__disclaimer {
    max-width: 420px;
    font-size: 10px;
    line-height: 140%;
    margin-bottom: 20px;
  }

  @media (max-width: $tablet) {
    margin-bottom: 40px;
  }

  .btn {
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}