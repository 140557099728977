@import "../../styles/vars";
@import "../../styles/media";

.radio-list {
  display: flex;
  margin: 0 -15px 25px;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    padding: 15px;
    max-width: 50%;
    min-width: 50%;

    @media (max-width: $tablet) {
      padding: 5px;
    }
    @media (max-width: $mobile) {
      min-width: 100%;
      max-width: 100%;
    }

    &--small {
      max-width: 33.33%;
      min-width: 33.33%;

      @media (max-width: $mobile) {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  &__button {
    display: flex;
    padding: 16px 20px 16px 65px;
    justify-content: flex-start;
    position: relative;
    border: 1px solid $blue;
    border-radius: 5px;
    background: $lighter-blue;
    color: $primary;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
  }

  &__button-input {
    display: none;
  }

  &__button-circle {
    position: absolute;
    left: 20px;
    top: 25px;
    width: 30px;
    height: 30px;
    border: 1px solid $gray;
    background: $white;
    border-radius: 50%;
    transition: border 0.15s linear;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: $primary;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.15s linear;
    }
  }

  &__button-text {
    display: flex;
    flex: 1;
    align-items: center;
    min-height: 48px;
  }

  &__button-input:checked + &__button-circle {
    border-color: $primary;

    &:before {
      opacity: 1;
    }
  }
}