.error-page {
  padding: 50px 0;
  background: $lighter-blue;

  .title {
    color: $primary;
    margin-bottom: 20px;

    @media (max-width: $tablet) {
      margin-bottom: 10px;
    }
  }

  &__content {
    background: $white;
    text-align: center;
    padding: 30px;

    p {
      color: $primary;
      font-size: 24px;
      line-height: 36px;

      @media (max-width: $tablet) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}