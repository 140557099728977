@import "../../styles/vars";
@import "../../styles/media";

.get-info {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 18px;

  @media (max-width: $tablet) {
    padding-top: 0;
    padding-bottom: 40px;
  }

  &__holder {

  }

  &__title {
    width: 730px;
    max-width: 100%;
    margin-bottom: 0;

    @media (max-width: $mobile) {
      margin-bottom: 15px;
    }
  }

  &__header {
    position: relative;
    min-height: 150px;
    padding: 0 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile) {
      flex-direction: column-reverse;
      padding: 0;
    }
  }

  &__label {
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $light-blue;
    color: $white;

    @media (max-width: $tablet) {
      width: 130px;
      height: 130px;
    }
    @media (max-width: $mobile) {
      position: static;
      margin-bottom: 15px;
    }
  }

  &__label-text {
    text-align: center;
    transform: matrix(0.99, -0.12, 0.12, 0.99, 0, 0);
    width: 116px;
  }

  &__step-info {
    color: $light-blue;
    margin-bottom: 53px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }

  &__question {
    color: $primary;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
    margin: -18px auto 40px;
    background: $white;
    padding: 0 3px;
  }

  &__sub-title {
    text-align: center;
    margin: -30px 0 25px;

  }

  &__form {
    border: 1px solid $blue;
    width: 860px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 65px 60px;

    @media (max-width: $tablet) {
      padding: 0 20px 29px;
    }
  }

  .btn {
    min-width: 126px;
  }

  &[data-current="1"] {
    #next {
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .btn-holder {
    @media (max-width: $mobile) {
      justify-content: space-between;
    }
  }
}