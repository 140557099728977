@import "../../styles/vars";
@import "../../styles/media";

.cards {
  padding-top: 100px;
  padding-bottom: 100px;
  background: $lighter-blue;

  @media (max-width: $tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__holder {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__col {
    width: 33.33%;
    padding: 0 15px;
    display: flex;
    justify-content: center;

    @media (max-width: $mobile) {
      width: 75%;
      padding: 10px 0px;
    }
  }

  &__col-image {
    position: relative;
    padding-bottom: 75%;
    max-width: 250px;
    width: 100%;

    img {
      position: absolute;
      height: 100%;
      width: auto;
      object-fit: contain;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: $mobile) {
      padding-bottom: 50%;
    }
  }
}
