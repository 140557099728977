.form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -35px;

  &__item {
    width: 50%;
    padding-left: 35px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      width: 100%;
      margin-bottom: 20px;
    }

    &--big {
      width: 100%;
    }
  }

  &__item-error {
    color: $red;
    font-size: 12px;
    line-height: 14px;
    display: none;
    padding-top: 5px;
  }

  &__field {
    display: block;

    &.error {
      .form__field-input {
        border-color: $red;
      }

      .form__item-error {
        display: block;
      }
    }
  }

  &__field-label {
    display: block;
    margin-bottom: 8px;
  }

  &__field-input,
  &__field-select {
    border: 1px solid $white;
    font-size: 14px;
    line-height: 24px;
    font-family: $frutiger;
    padding: 13px;
    border-radius: 5px;
    background-color: $white;
    color: $primary;
    transition: border 0.2s linear;

    &:focus {
      border-color: rgba($orange, 0.3);
    }
  }
}

.checkbox {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 1px 0 1px 35px;

  &__input {
    display: none;
  }

  &__body {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid $gray;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    background: $white;
    cursor: pointer;
    transition: border 0.1s linear;

    &:before {
      content: '';
      position: absolute;
      left: 6px;
      top: 1px;
      width: 7px;
      height: 11px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: $orange;
      transform: rotate(38deg);
      opacity: 0;
      transition: opacity 0.1s linear;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    flex: 1;
  }

  &__input:checked + &__body {
    border-color: $primary;

    &:before {

      opacity: 1;
    }
  }
}

.form-input {
  @extend .form__field-input;
  border: 1px solid $gray;
}