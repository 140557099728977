@import "../../styles/vars";
@import "../../styles/media";

.footer {
  background: $primary;
  color: $white;
  padding: 44px 0;
  margin-top: auto;

  @media (max-width: $mobile) {
    padding: 30px 0;
  }

  &__holder {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $mobile) {
      align-items: flex-start;
    }
  }

  &__nav {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    @media (max-width: $mobile) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 30px;
    }
  }

  &__nav-item {
    margin-left: 15px;
    padding-left: 16px;
    position: relative;

    @media (max-width: $mobile) {
      padding-left: 0;
      margin: 10px 0 0 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 2px;
      height: 19px;
      background: $white;
      transform: translateY(-50%);

      @media (max-width: $mobile) {
        display: none;
      }
    }

    &:first-child {
      margin-left: 0;
      padding-left: 0;

      @media (max-width: $mobile) {
        margin-top: 0;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }

  &__nav-link {
    color: $white;
    text-decoration: none;
    transition: color 0.2s linear;

    &:hover {
      color: $gray;
    }
  }
}