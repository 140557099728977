@font-face {
  font-family: 'frutiger';
  src: url('../assets/fonts/frutiger_lt_55_roman-webfont.eot');
  src: url('../assets/fonts/frutiger_lt_55_roman-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/frutiger_lt_55_roman-webfont.woff2') format('woff2'),
  url('../assets/fonts/frutiger_lt_55_roman-webfont.woff') format('woff'),
  url('../assets/fonts/frutiger_lt_55_roman-webfont.ttf') format('truetype'),
  url('../assets/fonts/frutiger_lt_55_roman-webfont.svg#webfontregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'frutiger';
  src: url('../assets/fonts/frutiger_lt_65_bold-webfont.eot');
  src: url('../assets/fonts/frutiger_lt_65_bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/frutiger_lt_65_bold-webfont.woff2') format('woff2'),
  url('../assets/fonts/frutiger_lt_65_bold-webfont.woff') format('woff'),
  url('../assets/fonts/frutiger_lt_65_bold-webfont.ttf') format('truetype'),
  url('../assets/fonts/frutiger_lt_65_bold-webfont.svg#webfontregular') format('svg');
  font-weight: 700;
  font-style: normal;

}