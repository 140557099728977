@import "../../styles/vars";
@import "../../styles/media";

.recommended-services {
  margin-bottom: 60px;

  @media (max-width: $tablet) {
    margin-bottom: 30px;
  }

  &__holder {
    border: 1px solid #CECECE;
    padding: 60px 30px;
    display: flex;

    @media (max-width: 1140px) {
      flex-wrap: wrap;
    }
    @media (max-width: $tablet) {
      padding: 30px;
    }
    @media (max-width: $mobile) {
      padding: 0;
      border: 0;
    }
  }

  &__description {
    display: flex;
    align-items: flex-start;
    padding: 0 20px;
    width: 48.45%;

    @media (max-width: 1140px) {
      width: 100%;
      margin-bottom: 20px;
    }
    @media (max-width: $tablet) {
      padding: 0;
    }
    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
  }

  &__description-holder {
    flex: 1;
  }

  &__description-title {
    margin: 0 0 10px;
    color: $primary;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
  }

  &__description-text {
    margin: 0;
    color: $dark-gray;
  }

  &__description-icon {
    margin-right: 30px;
    max-width: 60px;

    @media (max-width: $tablet) {
      margin-right: 10px;
    }
  }

  &__list {
    position: relative;
    display: flex;
    height: 218px;
    min-width: 540px;
    margin: 0 auto;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__list-title {
    font-size: 18px;
    line-height: 24px;
    color: $primary;
    width: 180px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -17px;
  }

  &__list-holder {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__list-item {
    position: absolute;
    color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /*&--01 {
      left: 22.6%;
      top: 0;
      background: $secondary;
      width: 16.3%;
      padding-top: 16.3%;

      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }*/

    &--01 {
      left: 22.6%;
      top: 0;
      background: $secondary;
      width: 88px;
      height: 88px;
    }

    &--02 {
      left: 42.6%;
      top: 4.13%;
      background: $light-blue;
      width: 100px;
      height: 100px;
    }

    &--03 {
      left: 64.8%;
      top: 11.47%;
      background: $secondary;
      width: 82px;
      height: 82px;
    }

    &--04 {
      left: 81.48%;
      top: 6.88%;
      background: $primary;
      width: 100px;
      height: 100px;
    }

    &--05 {
      left: 12.04%;
      top: 58.7%;
      background: $primary;
      width: 90px;
      height: 90px;
    }

    &--06 {
      left: 32.22%;
      top: 49.54%;
      background: $light-blue;
      width: 100px;
      height: 100px;
    }

    &--07 {
      left: 54.07%;
      top: 45.87%;
      background: $primary;
      width: 89px;
      height: 89px;
    }

    &--08 {
      left: 72.4%;
      top: 51.37%;
      background: $light-blue;
      width: 100px;
      height: 100px;
    }
  }
}