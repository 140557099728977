* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
}

img {
  outline: none;
  display: block;
  max-width: 100%;
}

input,
textarea,
select {
  outline: none;
  display: block;
  width: 100%;
  margin: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url(../assets/img/select-arrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
}

figure {
  margin: 0;

  &.cover {
    position: relative;

    picture {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: flex-start;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

p {
  margin: 0 0 10px;
}

a,
button {
  cursor: pointer;
}