@import "../../styles/vars";
@import "../../styles/media";

.contact-form {
  background: $light-blue;
  color: $white;

  &__holder {
    padding-left: 165px;
    display: flex;
    align-content: center;
    justify-content: space-between;

    @media (max-width: 1439px) {
      padding-left: 15px;
    }
    @media (max-width: $mobile) {
      flex-direction: column-reverse;
    }
  }

  &__content {
    width: 60%;
    padding: 100px 0 144px;
    position: relative;

    .preloader {
      position: absolute
    }

    @media (max-width: $tablet) {
      padding: 30px 0;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__title {
    color: $white;
    text-align: left;
    margin-bottom: 30px;
  }

  &__img {
    width: 40%;
    position: relative;
    display: flex;
    margin-right: -15px;

    @media (max-width: $mobile) {
      width: calc(100% + 30px);
      margin: 0 -15px;
      height: 240px;
    }

    &:before {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      background: $light-blue;
      width: 65px;
      clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
      z-index: 2;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    img {
      object-fit: cover;
    }
  }

  .btn {
    @media (max-width: $mobile) {
      flex: 1;
    }

  }

  &__success {
    display: flex;
    align-items: center;

    @media (max-width: $tablet) {
      align-items: flex-start;
    }
  }

  &__success-icon {
    width: 156px;
    height: 164px;

    @media (max-width: $tablet) {
      width: 70px;
      height: 70px;
    }
  }

  &__success-text {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    flex: 1;

    @media (max-width: $tablet) {
      font-size: 18px;
      line-height: 24px;
      font-weight: normal;
    }
  }
}