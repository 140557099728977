@import "../../styles/vars";
@import "../../styles/media";

.your-results {
  margin-bottom: 100px;

  @media (max-width: $tablet) {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 20px;

    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }

  &__text {
    margin-bottom: 46px;
    text-align: center;
    color: $dark-gray;
    font-size: 18px;
    line-height: 24px;

    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }

  &__cols {
    display: flex;
    justify-content: center;
    margin: 0 -15px 40px;

    @media (max-width: $mobile) {
      flex-direction: column;
      margin: 0;
    }
  }

  &__col {
    margin: 0 15px;
    border: 3px solid $light-blue;
    padding: 30px;
    width: 415px;
    max-width: calc(50% - 30px);

    @media (max-width: $mobile) {
      margin: 0 0 30px;
      max-width: 100%;
      width: 100%;
    }

    &:first-child {
      border-color: $gray;
    }
  }

  &__col-title {
    font-size: 18px;
    line-height: 24px;
    color: $primary;
    margin-bottom: 15px;
  }

  &__col {
    color: $primary;
    display: flex;
    flex-direction: column;

    &:first-child {
      .your-results__col-list {
        color: $dark-gray;
      }
    }

  }

  &__col-row {
    position: relative;
    padding-left: 25px;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 15px;
      height: 15px;
      background: url(../../assets/img/check-icon.svg) no-repeat 50% 50% / cover;
    }
  }

  &__col-list {
    margin-bottom: 35px;
    flex: 1;
  }

  &__col-sub-title {
    font-weight: bold;
    margin-bottom: 25px;
    display: block;
  }

  &__cost {
    height: 128px;
    display: flex;
    flex-direction: column;
  }

  &__diagram {
    min-height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $mobile) {
      min-height: 0;
    }
  }

  &__diagram-holder {
    display: flex;
    margin: 0 -10px;
    //align-items: flex-end;
  }

  &__cost,
  &__performance {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;
    max-width: calc(50% - 20px);
  }

  &__diagram-text {
    color: $white;
    font-weight: bold;
  }

  &__cost-progress {
    background: $primary;
  }

  &__cost-different {
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    padding: 5px;
    border: dashed $primary;
    border-width: 1px 1px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__cost-progress,
  &__performance-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
  }

  &__performance {
    //height: 90px;

    &--bigger {
      height: 177px;
    }
  }

  &__performance-progress {
    background: $light-blue;
  }

  &__performance-different {
    padding: 15px 5px;
    text-align: center;
    background: $primary;
    color: $white;
    font-weight: bold;
  }

  .btn {
    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &.default {

    .your-results {
      &__col {
        &:first-child {
          .your-results {
            &__cost {
              height: 128px;
            }

            &__cost-different {
              display: none;
            }

            &__performance {
              height: 90px;
              align-self: flex-end;
            }

            &__performance-different {
              display: none;
            }
          }
        }

        &:last-child {
          .your-results {
            &__cost {
              height: 130px;
              align-self: flex-end;
            }

            &__performance {
              height: 177px;
            }
          }
        }
      }
    }
  }

  &.without-extra-insurance {
    .your-results {
      &__col {
        &:first-child {
          .your-results__performance-different {
            visibility: hidden;
          }

          .your-results__cost-different {
            display: none;
          }
        }

        &:last-child {
          .your-results {
            &__cost-different {
              flex: 1;
            }

            &__cost-progress {
              min-height: 114px;
              max-height: 114px;
            }
          }
        }
      }

      &__cost {
        height: 168px;
      }

      &__cost-different {
        visibility: hidden;
      }

      &__performance-different {
        background: #FF6600;
        height: 32.5%;
      }
    }
  }

  &.without-extra-insurance-and-maximum-paid {
    .your-results {
      &__col {
        &:first-child {
          .your-results__performance-different {
            visibility: hidden;
          }

          .your-results__cost-different {
            height: 20.8%;
          }
        }

        &:last-child {
          .your-results {
            &__cost-different {
              flex: 1;
            }

            &__cost-progress {
              max-height: 114px;
              min-height: 114px;
              align-self: flex-end;
            }
          }
        }
      }

      &__cost {
        height: 168px;
      }

      &__cost-different {
        visibility: hidden;
      }

      &__performance-different {
        background: #FF6600;
        height: 32.5%;
      }
    }
  }

  &.extra-insurance {
    .your-results {
      &__col {
        &:first-child {
          .your-results__performance-different {
            background: #327DC8;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .your-results__cost-different {
            background: #001b3e;
            color: $white;
            border: 0;
            font-size: 14px;
            line-height: 20px;
          }
        }

        &:last-child {
          /*.your-results__cost-different {
            background: #001b3e;
            color: $white;
            border: 0;
          }*/
        }
      }

      &__cost-different {
        height: 34.5%;
      }

      &__cost {
        height: 168px;
      }

      &__performance-different {
        background: #FF6600;
        height: 32.5%;
      }
    }
  }

  &.children-and-maximum-amount {
    .your-results {

      &__col {
        &:first-child {
          .your-results__performance-different {
            visibility: hidden;
          }

          .your-results__cost-different {
            display: none;
          }
        }

        &:last-child {

          .your-results {
            &__cost {
              align-self: flex-end;
            }

            &__performance {
              height: 186px;
            }

            &__cost-different {
              height: 46px;
              border: 0;
              background: #0066B3;
              color: $white;
              font-size: 14px;
              line-height: 15px;
              font-weight: normal;
            }

            &__performance-different {
              background: #FF6600;
              font-size: 14px;
              line-height: 20px;
              font-weight: normal;
              color: $white;
              border: 0;
              height: 72px;
            }
          }
        }
      }

      &__cost-different {
        height: 59px;
      }

      &__cost {
        height: 160px;
      }

      &__performance-different {
        background: #FF6600;
        height: 46px;
      }
    }
  }

  &.children-and-maximum-amount-and-extra-insurance {
    .your-results {

      &__col {
        &:first-child {
          .your-results {
            &__performance {
              height: 173px;
              align-self: flex-end;
            }

            &__performance-different {
              height: 59px;
              background: #327DC8;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: normal;
            }

            &__cost {
              height: 191px;
            }

            &__cost-different {
              height: 59px;
              border: 0;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #002D67;
              color: $white;
              font-size: 14px;
              line-height: 15px;
              font-weight: normal;
            }
          }
        }

        &:last-child {

          .your-results {
            &__cost {
              align-self: flex-end;
            }

            &__performance {
              height: 186px;
            }

            &__cost-different {
              height: 46px;
              border: 0;
              background: #0066B3;
              color: $white;
              font-size: 14px;
              line-height: 15px;
              font-weight: normal;
            }

            &__performance-different {
              background: #FF6600;
              font-size: 14px;
              line-height: 20px;
              font-weight: normal;
              color: $white;
              border: 0;
              height: 72px;
            }
          }
        }
      }

      &__cost-different {
        height: 59px;
      }

      &__cost {
        height: 160px;
      }

      &__performance-different {
        background: #FF6600;
        height: 46px;
      }
    }
  }

  &.children-and-no-maximum-amount-and-no-extra-insurance {
    .your-results {

      &__col {
        &:first-child {
          .your-results {
            &__performance-different {
              visibility: hidden;
              flex: 1;
            }

            &__cost-different {
              visibility: hidden;
              flex: 1;
            }

            &__cost-progress,
            &__performance-progress {
              max-height: 114px;
              min-height: 114px;
            }
          }
        }

        &:last-child {

          .your-results {
            &__cost {
              align-self: flex-end;
            }

            &__performance {
              height: 186px;
            }

            &__cost-different {
              height: 46px;
              border: 0;
              background: #0066B3;
              color: $white;
              font-size: 14px;
              line-height: 15px;
              font-weight: normal;
            }

            &__performance-different {
              background: #FF6600;
              font-size: 14px;
              line-height: 20px;
              font-weight: normal;
              color: $white;
              border: 0;
              height: 72px;
            }
          }
        }
      }

      &__cost-different {
        height: 59px;
      }

      &__cost {
        height: 160px;
      }

      &__performance-different {
        background: #FF6600;
        height: 46px;
      }
    }
  }

  &.children-and-no-maximum-amount-and-extra-insurance {
    .your-results {

      &__col {
        &:first-child {
          .your-results {
            &__performance,
            &__cost {
              height: 173px;
            }

            &__performance-different {
              height: 59px;
              background: #327DC8;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &__cost-different {
              height: 59px;
              background: #001b3e;
              color: $white;
              border: 0;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        &:last-child {

          .your-results {
            &__cost {
              align-self: flex-end;
            }

            &__performance {
              height: 186px;
            }

            &__cost-different {
              height: 46px;
              border: 0;
              background: #0066B3;
              color: $white;
              font-size: 14px;
              line-height: 15px;
              font-weight: normal;
            }

            &__performance-different {
              background: #FF6600;
              font-size: 14px;
              line-height: 20px;
              font-weight: normal;
              color: $white;
              border: 0;
              height: 72px;
            }
          }
        }
      }

      &__cost-different {
        height: 59px;
      }

      &__cost {
        height: 160px;
      }

      &__performance-different {
        background: #FF6600;
        height: 46px;
      }
    }
  }
}
