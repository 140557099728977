.welcome {
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 290px;

    @media (max-width: $mobile) {
      height: 90px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__holder {
    position: relative;
    z-index: 2;
    background: $white;
    margin-top: -100px;
    padding: 50px 60px;
    width: 940px;

    @media (max-width: $mobile) {
      margin-top: 0;
      padding: 40px 15px;
    }
  }

  &__header {
    margin-bottom: 22px;
    text-align: center;
  }

  &__header-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);

    .animated-icon {
      margin-bottom: 0;
    }
  }

  &__header-text {
    font-size: 18px;
    margin: 0;
    color: #707070;

    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__steps {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  &__steps-item {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    @media (max-width: $mobile) {
      margin-top: 20px;
      width: 100%;
      text-align: left;
      flex-direction: row;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__step-separator {
    position: absolute;
    right: -10px;
    top: 40px;
    height: 20px;
    width: 20px;

    @media (max-width: $mobile) {
      left: 10px;
      bottom: -20px;
      padding: 5px;
      transform: rotate(90deg);
    }
  }

  &__steps-icon {
    background: $gray02;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile) {
      width: 40px;
      height: 40px;
      margin: 0 12px 0 0;
    }

    img {
      @media (max-width: $mobile) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__steps-name {
    font-size: 15px;
    line-height: 20px;
    flex: 1;
  }

  &__body {
    border-radius: 10px;
    padding: 20px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      padding: 10px;
      margin-bottom: 20px;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__body-text {
    margin-bottom: 20px;

    @media (max-width: $mobile) {
      margin-bottom: 10px;
    }
  }

  &__checkbox {
    margin-bottom: 15px;

    @media (max-width: $mobile) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .checkbox {
      &__body {
        background: $white;
      }

      &__text {
        font-size: inherit;
      }
    }
  }

  &__error {
    color: $red;
    text-align: center;
    display: block;
    line-height: 16px;
    margin-bottom: 10px;
  }

  &__btn {
    margin-bottom: 20px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
      text-align: center;
    }

    .btn {
      @media (max-width: $mobile) {
        padding: 5px 10px;
      }
    }
  }

  &__bottom-text {
    font-size: 12px;
    line-height: 13px;
    margin: 0;
    color: $gray03;
    text-align: center;
  }
}
