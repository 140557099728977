@import "../../styles/vars";
@import "../../styles/media";

.card {
  padding: 60px 30px;
  border: 1px solid #CECECE;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: $tablet) {
    padding: 20px;
    margin-bottom: 30px;
  }
  @media (max-width: $mobile) {
    padding: 0;
    border: 0;
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &__img {
    width: 47.37%;
    display: flex;
    height: 300px;

    @media (max-width: $tablet) {
      height: 250px;
      width: 40%;
    }
    @media (max-width: $mobile) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__description-icon {
    margin-right: 40px;
    width: 60px;

    @media (max-width: $tablet) {
      width: 50px;
      margin-right: 10px;
    }
  }

  &__description {
    display: flex;
    width: 44.73%;
    padding: 40px 0 20px;

    @media (max-width: $tablet) {
      padding: 0;
      width: 58%;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__description-holder {
    flex: 1;
  }

  &__description-heading {
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    color: $primary;
    margin: 0 0 10px;
  }

  &__description-text{
    color: $dark-gray;
    margin: 0;
  }
}