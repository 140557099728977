.login {
  &__img {
    display: flex;
    width: 31.5%;

    @media (max-width: $mobile) {
      height: 100px;
      width: 100%;
    }

    img {
      object-fit: cover;
    }
  }

  &__content {
    flex: 1;
    padding: 40px 60px;
    max-width: 100%;

    @media (max-width: $mobile) {
      padding: 30px 20px;
      text-align: center;

      .checkbox__text {
        text-align: left;
      }

      .btn {
        flex: 1;
      }
    }

    &--success {
      flex: 0;

      @media (max-width: $mobile) {
        padding-top: 65px;
      }
    }

    &--auth {
      min-height: 200px;

      @media (max-width: $mobile) {
        min-height: 120px;
      }
    }
  }

  &__title {
    color: $primary;
    margin-bottom: 15px;

    @media (max-width: $tablet) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  &__text {
    margin-bottom: 30px;
    font-size: 18px;

    @media (max-width: $tablet) {
      line-height: 30px;
    }
  }

  &__form {

  }

  &__input-error {
    color: $red;
    display: none;
    font-size: 12px;
  }

  &__input-wrapper {
    width: 350px;
    max-width: 100%;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      width: 100%;
    }

    &.error {
      .login {
        &__input {
          border-color: $red;
        }

        &__input-error {
          display: block;
        }
      }
    }
  }

  &__agreement-wrapper {
    margin-bottom: 45px;
    & > *:not(:last-child) {
      margin-bottom: 30px;
    }

    @media (max-width: $tablet) {
      margin-bottom: 30px;
    }
  }
}