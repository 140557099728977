// colors
$primary: #002d67;
$secondary: #ff6600;
$orange: #ff8f44;
$white: #fff;
$lighter-blue: #e1ebf5;
$light-blue: #0066b3;
$blue: #96bee6;
$gray: #cecece;
$dark-gray: #707070;
$gray02: #f0f0f0;
$gray03: #999;
$gray04: #444;
$gray05: #e9e9e9;
$red: #ff0000;

// fonts
$frutiger: "frutiger", Arial, Helvetica, sans-serif;
